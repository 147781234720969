let Site = {

    main : document.querySelector('main'),

    liftoff : _ => {
        Site.addImageOnCLick()
    },

    addImageOnCLick : _ => {

        document.body.addEventListener('click' , (event) => {
            
            // Do nothing if we're clicking on a link
            if (event.target == 'a') return

            // If we're here it means we need to do some calculations
            let x = event.clientX;
            let y = event.clientY;

            // Grab a few info to make the gallery work
            let current = parseInt(Site.main.dataset.current);
            let max     = parseInt(Site.main.dataset.max);

            // Insert a new image that is going to be x and y positioned on the click location
            let img     = document.createElement("img");
                img.src = window.location + `assets/images/image${current}.jpg`;
                img.style.top  = `${y}px`
                img.style.left = `${x}px`

            // Append the image to the body
            Site.main.appendChild(img);

            // Update the counter
            let next = (current < max) ? current + 1 : 1;
            Site.main.setAttribute('data-current' , next)

        })

    }
};

Site.liftoff();